import React from 'react'
import Nav from '../components/Nav'
import SustainabilityTopSection from '../components/SustainabilityTopSection'
import Footer from '../components/Footer'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import {Helmet} from "react-helmet";
import SectionHeading from '../components/SectionHeading'

//images
import sustainabilityImg1 from '../assets/img/sustainability/sustainability-hero@2x.jpg'
import image from '../assets/img/sustainability@2x.jpg'

class Sustainability extends React.Component{

    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://www.ldbconstruction.com/sustainability/#webpage",
                "url": "https://www.ldbconstruction.com/sustainability/",
                "name": "Sustainability Mission Statement - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "datePublished": "2024-07-02:20:19+00:00",
                "dateModified": "2024-07-02T08:25:41+00:00",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://www.ldbconstruction.com/sustainability/"
                    ]
                  }
                ]
              }
            ]
        }

        

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Sustainability Mission Statement - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB is committed to contributing to the creation of a sustainable future for all. We are constantly reviewing our business practices to ensure environmentally friendly solutions."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <section className="ldb_sustainability-section">
                      <Container>
                          <Row className="ldb_sustainability-bodycopy">
                              <Col xs={12} >
                                  <p>LDB Construction is committed to making a positive impact on the world. We continuously review our business practices to ensure environmentally friendly solutions, setting targets to minimise energy and water consumption while always working hard to limit waste and pollution. Wherever possible, we source sustainably produced, recyclable materials and always aim to use durable, reusable products.</p>
                                  <p>We no longer make use of single use plastic rubble bags and we partner with recycling specialists to ensure we recycle everything that we can. Our purchasing department gives preference to products with recycled content and multi-use materials. Our Bike to Work scheme continues to gain momentum. On several projects, bicycles now account for 75% or more of our site operatives' preferred mode of transport to and from work.</p>
                                  <p>These actions commit everyone at LDB Construction to achieving our goal of becoming London’s greenest super-prime residential contractor.</p>
                              </Col>
                          </Row> 
                          <Row>
                            <Col xs={12}>
                            <img src={image} className='ldb_sustainability-images'/>
                            </Col>
                          </Row>
                      </Container>
                  </section> 
                    
                </Nav>      
                <Footer />
            </React.Fragment>
        )
    }
}

export default Sustainability