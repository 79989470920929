import React from 'react'
import { Helmet } from "react-helmet";
import ldbLogo from '../assets/img/LDB-Construction-White.svg';
import image from '../assets/img/home-hero@2x.jpg';

class Home extends React.Component {

    state = {
        hide: false
    }

    hideSection = () => {
        setTimeout(() => {
            this.setState({ hide: true })
        }, 250);
    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "WebSite",
                    "@id": "https://www.ldbconstruction.com/#website",
                    "url": "https://www.ldbconstruction.com/",
                    "name": "LDB Construction",
                    "description": "LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area.",
                    "potentialAction": [
                        {
                            "@type": "SearchAction",
                            "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    ],
                    "inLanguage": "en-US"
                },
                {
                    "@type": "WebPage",
                    "@id": "https://www.ldbconstruction.com/#webpage",
                    "url": "https://www.ldbconstruction.com/",
                    "name": "LDB Construction",
                    "isPartOf": {
                        "@id": "https://www.ldbconstruction.com/#website"
                    },
                    "datePublished": "2014-02-26T15:20:02+00:00",
                    "dateModified": "2020-12-20T08:25:41+00:00",
                    "inLanguage": "en-US",
                    "potentialAction": [
                        {
                            "@type": "ReadAction",
                            "target": [
                                "https://www.ldbconstruction.com/"
                            ]
                        }
                    ]
                }
            ]
        }

        if (this.state.hide) {
            setTimeout(() => {
                window.location.href = '/about'
                console.log('redirect')
            }, 1000);
        }

        return (
            <React.Fragment>
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative', // Enables positioning for inner elements
                    }}
                >
                    <Helmet>
                        <title>Home - LDB Construction UK Ltd</title>
                        <link rel="canonical" href={window.location} />
                        <meta name="robots" content="index, follow" />
                        <meta
                            name="description"
                            content="LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area."
                        />
                        <script type="application/ld+json">
                            {JSON.stringify(ldJson)}
                        </script>
                    </Helmet>
                
                    <div 
  className={`_landing-section ${this.state.hide ? '_hide' : ''}`} 
  onClick={this.hideSection} 
  onWheel={this.hideSection} 
  onTouchMove={this.hideSection} 
  style={{  position: 'relative' }}
>
  <img
    style={{
      maxHeight: '100px',
      position: 'absolute', // Position relative to the parent
      right: '50px',       // Align to the right with 50px margin
      top: '50px',            // Align to the top of the parent
    }}
    src={ldbLogo}
    alt="LDB Construction UK Ltd"
  />
</div>

                  
                        
                </div>
            </React.Fragment>
        )
    }
}

export default Home;
