import React from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import CopywrightText from './CopywrightText'

class Footer extends React.Component{
    render(){
        return(
            <footer>
                <Container>
                    <Row>
                        <Col>
                            <CopywrightText />
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}

export default Footer