import React from 'react'
import {
    Link
} from 'react-router-dom'

class NextPrevNavProject extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className="ldb_next-prev-nav-projects">
                    {
                        this.props.prev && (
                            <Link className="ldb_inner ldb_prev" to={this.props.prev.search_name} title={this.props.prev.title}><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/></svg> Prev</Link>
                        )
                    }  
                    {
                        this.props.next && (
                            <Link to={this.props.next.search_name}><h2 className="ldb_page-heading" title="View Project">{this.props.next.title}</h2></Link>
                        )
                    }                                      
                    {
                        this.props.next && (
                            <Link className="ldb_inner ldb_next" to={this.props.next.search_name} title={this.props.next.title}>Next <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></Link>
                        )
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default NextPrevNavProject