import {
	FETCH_PROJECT
} from '../actions/types'

export default (state = {}, action) => {
	switch(action.type){
		case FETCH_PROJECT:
			return {
				...state,
				[action.payload.searchName]: action.payload.data
			}
		default:
			return state
	}
}