import React from 'react'
import Nav from '../components/Nav'
import TopSection from '../components/TopSection'
import Footer from '../components/Footer'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import {Helmet} from "react-helmet";

//images
import video from '../assets/videos/LDB-Wellness-Process-01.mp4'
import image from '../assets/img/wellness@2x.jpg'

class Covid extends React.Component{
  

    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://www.ldbconstruction.com/covid/#webpage",
                "url": "https://www.ldbconstruction.com/covid/",
                "name": "Covid - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "datePublished": "2014-02-26T15:20:19+00:00",
                "dateModified": "2020-12-20T08:25:41+00:00",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://www.ldbconstruction.com/Covid/"
                    ]
                  }
                ]
              }
            ]
        }

        

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Wellness - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB Construction is a luxury residential building refurbishment and maintenance company, specialising in standard-bearing design and build solutions for exclusive clients in prime areas of central London."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <section className="ldb_about-section">
                    <Container>
                        <Row className='ldb_about-bodycopy'>
                          <Col xs={12}>
                            <p>Health, safety and the wellness of our staff are fundamental to the continued success of our business. </p>
                            <p>We believe in job security, financial security, health, support, protection and work-life balance. We have always known that employee performance is directly linked to wellbeing in the workplace. With our large direct team of employees that make up the LDB family, we have set ourselves firmly apart from our competition and we continue to invest and commit to this way of working in all that we do.</p>
                          </Col>
                        </Row>
                          <Row>
                              <Col xs={12}>
                                  <img src={image} className='ldb_about-images'/>
                              </Col>
                          </Row>
                      </Container> 
                  
                  </section> 

                </Nav>      
                <Footer />
            </React.Fragment>
        )
    }
}

export default Covid