import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import {Helmet} from "react-helmet";
import PageHeading from '../components/PageHeading'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

class Accreditations extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Accreditations - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area."
                    />
                </Helmet>
                <Nav>                    
                <section className="ldb_accreditation-section">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <PageHeading 
                                        text="Accreditation"
                                    />
                                </Col>
                                <Col sm={12}>
                                    <p>LDB Construction is committed to adhering to the highest level of practice and industry standards.</p>
                                </Col>
                                <Col sm={12}>
                                    <h2>Considerate Constructors Scheme</h2>
                                    <p>We have been proud members of the Considerate Constructors Scheme since 2013. The voluntary Scheme which has become the industry standard asks contractors to abide by an agreed Code of Practice across their sites and business operations. The scheme is particularly relevant in areas that are sensitive locations. The Scheme is concerned about any area of construction activity that may have a direct or indirect impact on the image of the industry as a whole. The main areas of concern fall into three categories: the general public, the workforce and the environment. Some of the benefits of being part of this nationally recognised Scheme include:</p>
                                    <ul>
                                    <li>Participation demonstrates a commitment to high standards within in the industry and is now considered the industry standard.</li>
                                    <li>It is viewed positively by planning authorities, demonstrating a commitment to community engagement and minimising impact.</li>
                                    <li>Independent assessment by experienced industry professionals.</li>
                                    <li>Assess and benchmark performance and strive for development and improvement.</li>
                                    </ul>
                                    <p>For more information about the scheme, please visit Considerate Contractors website <a href="https://www.ccscheme.org.uk" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                </Col>
                                
                                <Col sm={12}>
                                    <h2>SAFE contractor</h2>
                                    <p>SAFE contractor is the premier health and safety accreditation scheme for construction businesses in the UK. The scheme sets the current benchmark for contractors operating in the built environment.</p>
                                </Col>

                                <Col sm={12}>
                                    <h2>The Guild of Master Craftsmen</h2>
                                    <p>The Guild’s stringent entry criteria ensure that only those companies who meet the requirements are awarded membership, which is by no means automatic. Members are required to prove that they have earned the right to be called master craftsmen through the quality of their work, their commitment to customer care and the level of service they provide.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>  
                <Footer />              
            </React.Fragment>
        )
    }
}

export default Accreditations