import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import TopSection from '../components/TopSection'
import {
    fetchProject
} from '../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../path'
import NextPrevNavProject from '../components/NextPrevNavProject'
import {Helmet} from "react-helmet";
import {Redirect} from 'react-router-dom'

// videos
import chelsea from '../assets/project-videos/01-Chelsea.mp4'
import hollandPark from '../assets/project-videos/02-Holland-Park.mp4'
import knightsbridge1 from '../assets/project-videos/03-Knightsbridge.mp4'
import knightsbridge from '../assets/project-videos/16-Knightsbridge.mp4'
import chelseaTheBoltons from '../assets/project-videos/05-Chelsea.mp4'
import belgravia2 from '../assets/project-videos/07-Belgravia.mp4'
import belgravia from '../assets/project-videos/06-Belgravia.mp4'
import knightsbridge4 from '../assets/project-videos/19-Knightsbridge.mp4'
import belgravia3 from '../assets/project-videos/09-Belgravia.mp4'
import hampstead from '../assets/project-videos/10-Hampstead.mp4'
import wimbledonVillage from '../assets/project-videos/11-Wimbledon-Village.mp4'
import fulham from '../assets/project-videos/12-Fulham.mp4'
import mayfair from '../assets/project-videos/13-Mayfair.mp4'
import chelsea1 from '../assets/project-videos/14-Chelsea.mp4'
import bayswater from '../assets/project-videos/15-Bayswater.mp4'
import knightsbridge5 from '../assets/project-videos/08-Knightsbridge.mp4'
import draycottPlace from '../assets/project-videos/17-Draycott-Place.mp4'
import camden from '../assets/project-videos/18-Camden.mp4'
import knightsbridge2 from '../assets/project-videos/04-Knightsbridge.mp4'


class Project extends React.Component{

    componentDidMount(){
        this.props.fetchProject(this.props.match.params.searchName)
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.searchName !== this.props.match.params.searchName){
            this.props.fetchProject(this.props.match.params.searchName)
        }
    }

    EmbedVideo = video => {
    return (
        <div  className="ldb_video" dangerouslySetInnerHTML={{ __html: `
            <video title="ldbYoutubeVideo" width="560" height="315" autoplay loop playsinline controls>
                <source src=${video} type="video/mp4" />

            Your browser does not support the video tag.
            </video>
            ` }}></div>
        )
    }

    renderGallery(){
        
        let i = 0
        const pattern = [3,4,6,7]
        let num

        return this.props.project.gallery.map((item, index) => {

            i++ 
            num = pattern.indexOf(i) !== -1 ? 6 : 12
            if(i === 7){
                i = 0
            }

            // if pattern needed, uncomment the following line
            num = 12
            
            return (                
                <Col md={num} key={index}>
                    <figure>
                        <img src={`${BASE_URL}${item.image}`} alt={`${this.props.project.title} ${index + 1}`} />
                    </figure>                                  
                </Col>                
            )   
        })
    }

    render(){

        if(!this.props.project){
            return <></>
        }

        if(this.props.project === "Not Found"){
            return <Redirect to="/projects" />
        }

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "WebSite",
                    "@id": "https://www.ldbconstruction.com/#website",
                    "url": "https://www.ldbconstruction.com/",
                    "name": "LDB Construction",
                    "description": "LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area.",
                    "potentialAction": [
                        {
                            "@type": "SearchAction",
                            "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    ],
                    "inLanguage": "en-US"
                },
                {
                    "@type": "ImageObject",
                    "@id": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/#primaryimage`,
                    "inLanguage": "en-US",
                    "url": BASE_URL+this.props.project.image,
                    "width": 740,
                    "height": 500
                },
                {
                    "@type": "WebPage",
                    "@id": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/#webpage`,
                    "url": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/`,
                    "name": `${this.props.project.title} - LDB Construction UK Ltd`,
                    "isPartOf": {
                        "@id": "https://www.ldbconstruction.com/#website"
                },
                    "primaryImageOfPage": {
                        "@id": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/#primaryimage`
                },
                    "datePublished": this.props.project.created_at,
                    "dateModified": this.props.project.updated_at,
                    "inLanguage": "en-US",
                    "potentialAction": [
                        {
                            "@type": "ReadAction",
                            "target": [
                                `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/`
                            ]
                        }
                    ]
                }
            ]
        }

        const videos = {
            chelsea,
            'holland-park': hollandPark,
            'knightsbridge-1': knightsbridge1,
            knightsbridge,
            'chelsea–the-boltons': chelseaTheBoltons,
            'belgravia-2': belgravia2,
            belgravia,
            'knightsbridge-4': knightsbridge4,
            'belgravia-3': belgravia3,
            hampstead,
            'wimbledon-village': wimbledonVillage,
            fulham,
            mayfair,
            'chelsea-1': chelsea1,
            bayswater,
            'knightsbridge-5': knightsbridge5,
            'draycott-place': draycottPlace,
            camden,
            'knightsbridge-2': knightsbridge2
        }

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>{this.props.project.title ? `${this.props.project.title}  - LDB Construction UK Ltd` : ''}</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content={this.props.project.meta_description ? this.props.project.meta_description : ''}
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>
                    <TopSection 
                        heading={this.props.project.title}
                        text={this.props.project.body}
                        back="/projects"
                        backText="Back To Projects"
                    />

                    {/* {
                        videos[this.props.project.search_name] && (
                            <section className="ldb_product-section">
                                
                                <Container fluid>
                                    <Row>                                       
                                        <Col md={12} style={{marginBottom: '30px'}}>
                                             {this.EmbedVideo(videos[this.props.project.search_name])}                                
                                        </Col> 
                                    </Row>
                                </Container>
                            </section>
                        )
                    } */}
                    
                    <section className="ldb_product-section">
                        <Container fluid>
                            <Row>
                                {this.renderGallery()}
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col>
                                    <NextPrevNavProject 
                                        next={this.props.project.next ? this.props.project.next : null}
                                        prev={this.props.project.previous ? this.props.project.previous : null}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>            
                <Footer />    
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.project[ownProps.match.params.searchName]
    }
}

export default connect(mapStateToProps, {
    fetchProject
})(Project)