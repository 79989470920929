import React from 'react'
import {
    Link
} from 'react-router-dom'
import Popup from './Popup'
import guidelinesVideo from '../assets/videos/Covid-2021-Website.mp4'

class CopywrightText extends React.Component{

    state = {
        showVideoPopup: false
    }

    renderVideoPopup = () => {
        this.setState({
            showVideoPopup: !this.state.showVideoPopup
        })
    }

    EmbedVideo = () => {
        return (
            <div  className="ldb_video" dangerouslySetInnerHTML={{ __html: `
                <video title="ldbYoutubeVideo" width="560" height="315"  autoplay loop playsinline controls>
                    <source src=${guidelinesVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ` }}></div>
        )
    }
    
    render(){
        const date = new Date();
        const year = date.getFullYear();
        return(
            <>
                <small className="ldb_copywright-text">© {year} LDB Construction | <Link to="/accreditations" >Accreditations</Link> | <Link to="/legal">T’s & C’s</Link> | <a href="javasript:void(0)" onClick={this.renderVideoPopup}>Covid Guidelines</a> | <a rel="noopener noreferrer" href="https://www.optimalonline.co.za/" target="_blank">Optimal Online</a></small>
                {
                    this.state.showVideoPopup && (
                        <Popup onClick={this.renderVideoPopup}>
                            {this.EmbedVideo()}
                        </Popup>
                    )
                }
            </>
        )
    }
}

export default CopywrightText