import React from 'react'
import {
    Col
} from 'react-bootstrap'
import {
    Link
} from 'react-router-dom'

class ProjectItem extends React.Component{

    render(){
        return(
            <Col md={6} xl={4}>
                <div className="ldb_products-item" style={{
                    backgroundImage: `url(${this.props.image})`,
                }}>
                    <Link to={this.props.link} title="View project" />
                    <h3 className="ldb_title">{this.props.title} 
                        <svg className="ldb_arrow" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
                    </h3>
                    
                </div>
            </Col>
        )
    }
}

export default ProjectItem