import ldb from '../api'
import {
	FETCH_PROJECTS,
    FETCH_PROJECT,
    FETCH_TEAM,
    FETCH_MEMBER,
    PRIVATE_USER_LOGIN,
    PRIVATE_USER_LOGOUT,
    PRIVATE_USER_LOGIN_FAILED,
    FETCH_PRIVATE_PROJECTS,
    FETCH_PRIVATE_PROJECT,
    RSVP_SUBMIT,
    RSVP_SUBMIT_CLEAR
} from './types'
import history from '../history'

export const privateUserLogin = formValues => async dispatch => {

    try{

        const response = await ldb.post('/auth/login', formValues)

        dispatch({
            type: PRIVATE_USER_LOGIN,
            payload: response.data
        })

    } catch (error){

        dispatch({
            type: PRIVATE_USER_LOGIN_FAILED,
            payload: error
        })
    }
}

export const privateUserLogout = token => async dispatch => {

    try{
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const data = {}

        const response = await ldb.post('/auth/logout', data, config)

        dispatch({
            type: PRIVATE_USER_LOGOUT
        })
        
    } catch(error){

        dispatch({
            type: PRIVATE_USER_LOGOUT
        })
    }

}

export const fetchPrivateProjects = token => async dispatch => {

    try{
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await ldb.get('/private-projects', config)

        dispatch({
            type: FETCH_PRIVATE_PROJECTS,
            payload: response.data
        })
    } catch (error){
        console.log(error)
    }

}

export const fetchPrivateProject = (searchName, token) => async dispatch => {

    const config = { 
        headers: { 
            Authorization: `Bearer ${token}` 
        } 
    };

    const response = await ldb.get(`/private-projects/${searchName}`, config)

    dispatch({
        type: FETCH_PRIVATE_PROJECT,
        payload: {
            data: response.data,
            searchName
        }
    })

}

export const fetchProjects = () => async dispatch => {

	const response = await ldb.get('/projects')

	dispatch({
        type: FETCH_PROJECTS,
        payload: response.data
    })
}

export const fetchProjectsLanding = () => async dispatch => {

	const response = await ldb.get('/projects-landing')

	dispatch({
        type: FETCH_PROJECTS,
        payload: response.data
    })
}

export const fetchProject = searchName => async dispatch => {

	const response = await ldb.get(`/projects/${searchName}`)

	dispatch({
        type: FETCH_PROJECT,
        payload: {
            data: response.data,
            searchName
        }
    })
}

export const fetchTeam = () => async dispatch => {

	const response = await ldb.get('/team')

	dispatch({
        type: FETCH_TEAM,
        payload: response.data
    })
}

export const fetchMember = searchName => async dispatch => {

	const response = await ldb.get(`/team/${searchName}`)

	dispatch({
        type: FETCH_MEMBER,
        payload: {
            data: response.data,
            searchName
        }
    })
}

export const submitRSVP = formValues => async dispatch => {

    try {

        const response = await ldb.post('/entries/create', formValues)

        dispatch({
            type: RSVP_SUBMIT,
            payload: response.data
        })
        
    } catch (error) {

        console.log('error', error)
    }
}

export const clearRSVP = () => {
    return (dispatch) => {
        dispatch({
            type: RSVP_SUBMIT_CLEAR
        })
    }
}