import React from 'react'
import Nav from '../components/Nav'
import TopSection from '../components/TopSection'
import Footer from '../components/Footer'
import {Helmet} from "react-helmet";
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import SectionHeading from '../components/SectionHeading'
//images
import image from '../assets/img/aftercare@2x.jpg';

class Aftercare extends React.Component{
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "CollectionPage",
                "@id": "https://www.ldbconstruction.com/aftercare/#webpage",
                "url": "https://www.ldbconstruction.com/aftercare/",
                "name": "Aftercare - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "inLanguage": "en-US"
              }
            ]
        }

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Aftercare - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="Welcome to LDB Property Management, a subsidiary of LDB Construction UK Ltd. At LDB Property Management, we offer a unique and tailored property management service to our valued customers."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav> 
                    <section className="ldb_aftercare-section">
                      <Container>
                          <Row className="ldb_aftercare-bodycopy">
                              <Col xs={12}>
                                  <p>LDB Construction offers an exclusive property maintenance service for our clients, providing bespoke solutions for their individual property needs.  </p>
                                  <p>We understand how to work in furnished and occupied high-value homes, always prioritising discretion and confidentiality. Our reputation for transparency and excellence in the prime central London market is exceptionally well established.</p>
                                  <p>Through access to LDB Construction’s workforce and resources, our aftercare division can manage small building works or alterations in real time. Our highly skilled in-house tradesmen are equipped to handle a wide variety of tasks from re-fitting bathrooms and installing replacement kitchens to redecorating an entire home — all with minimal lead time. </p>
                              </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                            <img src={image} className='ldb_aftercare-images'/>
                            </Col>
                          </Row>
                      </Container>
                  </section>
                </Nav> 
                <Footer />               
            </React.Fragment>
        )
    }
}

export default Aftercare