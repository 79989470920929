import React from 'react'

class PageText extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className="mb-4" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
                <div className="ldb_horline"></div>
            </React.Fragment>
            
        )
    }
}

export default PageText