import React from 'react'
import Nav from '../components/Nav'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import {Helmet} from "react-helmet";

import img from '../assets/img/contact-us@2x.jpg'

class Contact extends React.Component{
    embeddedImage = () => {
        return React.createElement(
          'div',
          {
            className: 'ldb_video',
            style: {
              margin: 0,                  // Remove margin
              padding: 0,                 // Remove padding
            //   marginBottom: '50px'
            },
          },
          React.createElement('img', {
            src: img,
            title: 'ldbHeroes',
            width: 1170,
            alt: 'ldbHeroes',
          })
        );
      };
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://www.ldbconstruction.com/contact/#webpage",
                "url": "https://www.ldbconstruction.com/contact/",
                "name": "Contact Us - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "datePublished": "2014-02-26T15:29:52+00:00",
                "dateModified": "2020-12-20T08:25:41+00:00",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://www.ldbconstruction.com/contact/"
                    ]
                  }
                ]
              }
            ]
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>Contact Us - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="Please contact us by phone or email. +44 (0)20 3327 0303, info@ldbconstruction.com. Address: Unit 9 Woodman Works, WImbldon Park, London, SW19 8DR."
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>                    
                    <section>
                      <Container>
                        <Row className='ldb_contact-text'>
                          <Col>
                          <p>
                            <a href="tel:+44 20 3327 0303">+44 (0)20 3327 0303</a><br />
                            <a href="mailto:info@ldbconstruction.com?subject=LDB Construction Enquiry">
                                info@ldbconstruction.com
                            </a>
                        </p>
                          </Col>
                          <Col>
                          <p>
                              9 Woodman Works, 204 Durnsford Road, London, SW19 8DR
                          </p>
                          </Col>
                        
                        </Row>
                          <Row>
                          <Col xs={12}>
                            <img src={img} className='ldb_contact-image'/>
                          </Col>
                          </Row>
                      </Container>
                  </section>
                </Nav>   
                <Footer />
            </React.Fragment>
        );
    }
}

export default Contact