import {
    RSVP_SUBMIT,
    RSVP_SUBMIT_CLEAR
} from '../actions/types'

const initialState = {}

export default (state = initialState, action) => {
    switch(action.type){
        case RSVP_SUBMIT: 
            return action.payload
        case RSVP_SUBMIT_CLEAR:
            return initialState
        default:
            return state
    }
}