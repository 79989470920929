import React from 'react'
import Nav from '../components/Nav'
import {Helmet} from "react-helmet"
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

class Legal extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Legal, privacy and terms of access - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content="LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area."
                    />
                </Helmet>
                <Nav>
                    <section className="ldb_legal-section">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <PageHeading 
                                        text="Legal, privacy and terms of access"
                                    />
                                </Col>
                            </Row>
                        </Container>
                                        
                        <Container>
                            <Row>
                                <Col sm={12}>                                        
                                    <h2 style={{marginTop: '0px'}}>Terms and conditions of access:</h2>
                                    <p>Your use of the LDB Construction UK Ltd website is subject to the following terms and conditions, if you continue to browse and use this website you are agreeing to comply with, and be bound by, the following terms and conditions of use which, together with our privacy policy, govern our relationship with you in relation to this website.</p>
                                    <p>The term ‘LDB Construction UK Ltd’ or ‘LDB Construction’, ‘LDB’, ‘us’, ‘our’ or ‘we’ refers to the owner of the website whose registered office is: 9 Woodman Works, 204 Durnsford Road, London, SW18 8DR. Registered in England and Wales, our company registration number is Company No.: 6081563. The term ‘you’ refers to the user or viewer of our website.</p>
                                    <p>The use of this website is subject to the following terms of use:</p>
                                    <ul>
                                    <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                                    <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                                    <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                                    <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                                    <li>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                                    <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                                    <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                                    <li>You may not create a link to this website from another website or document without LDB Construction UK Ltd’s prior written consent.</li>
                                    <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Scotland and Wales.</li>
                                    </ul>
                                    <h2>Copyright Notice:</h2>
                                    <p>This website and its content is copyright of LDB Construction UK Ltd – ©2015 LDB Construction UK Ltd. All rights reserved.</p>
                                    <p>Any redistribution or reproduction of part or all of the contents of this site in any form is prohibited. You may not, except with our express written permission, distribute or commercially exploit the content, design or photography displayed on this site. Nor may you transmit or store the content or imagery on this site in any other website or other form of electronic retrieval system. For press enquiries or further information, please contact: <a href="mailto:info@ldbconstruction.com">info@ldbconstruction.com</a></p>
                                    <p>For the purposes of viewing the site, permission is granted to temporarily download one copy of the materials for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                                    <ul>
                                    <li>Modify or copy the materials;</li>
                                    <li>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                    <li>Attempt to decompile or reverse engineer any software contained on name’s web site;</li>
                                    <li>Remove any copyright or other proprietary notations from the materials; or</li>
                                    <li>Transfer the materials to another person or “mirror” the materials on any other server.</li>
                                    </ul>
                                    <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by name at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
                                    <h2>Disclaimer:</h2>
                                    <p>The information contained in this website is for general information purposes only. The information is provided by LDB Construction UK Ltd and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
                                    <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                                    <p>Through this website you are able to link to other websites which are not under the control of LDB Construction UK Ltd. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                                    <p>Every effort is made to keep the website up and running smoothly. However, LDB Construction UK Ltd takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
                                    <h2>Privacy Policy:</h2>
                                    <p>This privacy policy sets out how LDB Construction UK Ltd uses and protects any information that you give LDB Construction UK Ltd when you use this website.</p>
                                    <p>LDB Construction UK Ltd is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
                                    <p>LDB Construction UK Ltd may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 01.01.2015.</p>
                                    <p>LDB Construction UK Ltd takes our clients’ and our website visitors’ privacy seriously. We will never sell, rent or disclose your personal information to any third party without your consent. Unless you give LDB Construction UK Ltd express permission to act otherwise, the following privacy policy will guide the way we treat any personal information provided to us on this website.</p>
                                    <p>We may collect the following information:</p>
                                    <ul>
                                    <li>Name, job title and type of industry / occupation</li>
                                    <li>Contact information including email address and telephone numbers</li>
                                    <li>Demographic information such as postcode, preferences and interests</li>
                                    <li>Other information relevant to customer surveys and/or offers</li>
                                    </ul>
                                    <p>What we do with the information we gather:</p>
                                    <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                                    <ul>
                                    <li>Internal record keeping.</li>
                                    <li>We may use the information to improve our products and services.</li>
                                    <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                                    <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail.</li>
                                    <li>We may use the information to customise the website according to your interests.</li>
                                    </ul>
                                    <p>Controlling your personal information:</p>
                                    <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                                    <p>If you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.</p>
                                    <p>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to LDB Construction UK Ltd, 9 Woodman Works, 204 Durnsford Road, London, SW18 8DR; or emailing us at <a href="mailto:info@ldbconstruction.com">info@ldbconstruction.com</a></p>
                                    <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
                                    <h2>Links to other websites:</h2>
                                    <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
                                    <p>You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to LDB Construction UK Ltd, <a href="mailto:info@ldbconstruction.com">info@ldbconstruction.com</a></p>
                                    <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
                                    <p>If you have any questions about this privacy statement please email <a href="mailto:info@ldbconstruction.com">info@ldbconstruction.com</a> or contact us directly on 0203 327 0303.</p>
                                    <h2>Cookies and how we use them:</h2>
                                    <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. We use ‘traffic log’ cookies to identify which pages are being used. ‘Traffic log’ cookies collect information about how you use our website e.g. which pages you visit, and if you experience any errors. These cookies do not collect any information that could identify you – all the information collected is anonymous and is only used to help us improve how our website works, understand what interests our users and improve our website in order to tailor it to customer needs.</p>
                                    <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                                    <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
                                    <p>Our website uses three (3) persistent cookies. Read more about the individual analytical cookies we use and how to recognise them below.</p>
                                    <p>Google Analytics:</p>
                                    <ul>
                                    <li>SESSION</li>
                                    <li>Analytics</li>
                                    <li>UserLocale</li>
                                    </ul>
                                    <p>Google Analytics is Google’s free web analytics tool that helps website owners understand how their visitors engage with their website. Google Analytics collects information anonymously, and much like examining footprints in sand, it reports website trends without identifying individual visitors. Analytics uses its own set of cookies to track visitor interactions.</p>
                                    <p>These cookies are used to store information, such as what time the current visit occurred, whether the visitor has been to the site before, and what site referred the visitor to the web page. Google Analytics customers can view a variety of reports about how visitors interact with their website so they can improve their website and how people find it. A different set of cookies is used for each website, and visitors are not tracked across multiple sites.</p>
                                    <p>Analytics customers are obliged to notify users of their use of analytics software. To disable this type of cookie, some browsers will indicate when a cookie is being sent and allow you to decline cookies on a case-by-case basis. In addition to declining cookies, you can also install the Google Analytics Opt-out Add-on in your browser, which prevents Google Analytics from collecting information about your website visits.</p>
                                    <p>View Google’s Policy <a href="http://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/learn/privacy.html</a></p>                                        
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>   
                <Footer />             
            </React.Fragment>
        )
    }
}

export default Legal