import React from 'react'

class SectionHeading extends React.Component{
    render(){
        return(
            <h2 className="ldb_section-heading">{this.props.text}</h2>
        )
    }
}

export default SectionHeading