import React from 'react'
import {
    Link
} from 'react-router-dom'

class BackOneLevel extends React.Component{
    render(){
        return(
            <div className="ldb_backonelevel-button">
                <Link to={this.props.link}>
                    <svg className="ldb_arrow" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/></svg>
                    {this.props.text ? this.props.text : "Back"}                    
                </Link>                
            </div>            
        )
    }
}

export default BackOneLevel