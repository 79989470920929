import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import TopSection from '../components/TopSection'
import {
    fetchPrivateProject
} from '../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../path'
import NextPrevNavProject from '../components/NextPrevNavProject'
import {Helmet} from "react-helmet";
import {Redirect} from 'react-router-dom'
import AuthLogin from '../components/AuthLogin'
import AuthSignout from '../components/AuthSignout'

class Project extends React.Component{

    state = {
        fetched: false
    }

    componentDidMount(){
        if(this.props.auth.token){
            if(!this.state.fetched){
                this.props.fetchPrivateProject(this.props.match.params.searchName, this.props.auth.token)
                this.setState({
                    fetched: true
                })
            }
            
        }       
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.searchName !== this.props.match.params.searchName){
            this.setState({
                fetched: false
            })
        }
        if(this.props.auth.token){
            if(!this.state.fetched){
                this.props.fetchPrivateProject(this.props.match.params.searchName, this.props.auth.token)
                this.setState({
                    fetched: true
                })
            }                
        }
        
    }

    renderGallery(){
        
        let i = 0
        const pattern = [3,4,6,7]
        let num

        return this.props.project.gallery.map((item, index) => {

            i++ 
            num = pattern.indexOf(i) !== -1 ? 6 : 12
            if(i === 7){
                i = 0
            }

            // if pattern needed, uncomment the following line
            num = 12
            
            return (                
                <Col md={num} key={index}>
                    <figure>
                        <img src={`${BASE_URL}${item.image}`} alt={`${this.props.project.title} ${index + 1}`} />
                    </figure>                                  
                </Col>                
            )   
        })
    }

    render(){

        if(!this.props.auth.token){
            return (
                <React.Fragment>
                    <Helmet>
                        
                        <title>Private Projects Login - LDB Construction UK Ltd</title>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                    <Nav> 
                        <TopSection 
                            heading="private projects"
                            text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus culpa quas, eos voluptatum aliquam quod ex exercitationem repellat dolorem! Voluptatum iusto ex, repudiandae repellat pariatur porro accusamus ducimus culpa reiciendis."
                        />
                        <section className="ldb_products-section">
                            <Container>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <AuthLogin />
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Nav>            
                    <Footer />    
                </React.Fragment>
            )
        }

        if(!this.props.project){
            return <></>
        }

        if(this.props.project === "Not Found"){
            return <Redirect to="/projects" />
        }

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "WebSite",
                    "@id": "https://www.ldbconstruction.com/#website",
                    "url": "https://www.ldbconstruction.com/",
                    "name": "LDB Construction",
                    "description": "LDB Construction is a luxury residential building, refurbishment and maintenance company, specialising in cutting-edge design and build solutions for exclusive clients in the prime central London area.",
                    "potentialAction": [
                        {
                            "@type": "SearchAction",
                            "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    ],
                    "inLanguage": "en-US"
                },
                {
                    "@type": "ImageObject",
                    "@id": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/#primaryimage`,
                    "inLanguage": "en-US",
                    "url": BASE_URL+this.props.project.image,
                    "width": 740,
                    "height": 500
                },
                {
                    "@type": "WebPage",
                    "@id": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/#webpage`,
                    "url": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/`,
                    "name": `${this.props.project.title} - LDB Construction UK Ltd`,
                    "isPartOf": {
                        "@id": "https://www.ldbconstruction.com/#website"
                },
                    "primaryImageOfPage": {
                        "@id": `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/#primaryimage`
                },
                    "datePublished": this.props.project.created_at,
                    "dateModified": this.props.project.updated_at,
                    "inLanguage": "en-US",
                    "potentialAction": [
                        {
                            "@type": "ReadAction",
                            "target": [
                                `https://www.ldbconstruction.com/projects/${this.props.match.params.searchName}/`
                            ]
                        }
                    ]
                }
            ]
        }

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>{this.props.project.title ? `${this.props.project.title}  - LDB Construction UK Ltd` : ''}</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="index, follow" />
                    <meta
                        name="description"
                        content={this.props.project.meta_description ? this.props.project.meta_description : ''}
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                <Nav>
                    <TopSection 
                        heading={this.props.project.title}
                        text={this.props.project.body}
                        back="/private-projects"
                        backText="Back To Projects"
                    />
                    <section className="ldb_product-section">
                        <Container fluid>
                            <Row>
                                {this.renderGallery()}
                            </Row>
                            <Row>
                                <Col>
                                    <NextPrevNavProject 
                                        next={this.props.project.next ? this.props.project.next : null}
                                        prev={this.props.project.previous ? this.props.project.previous : null}
                                    />
                                </Col>
                            </Row>
                             <Row>
                                <Col sm={12} className="text-center">
                                    <AuthSignout />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>            
                <Footer />    
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.privateProject[ownProps.match.params.searchName],
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    fetchPrivateProject
})(Project)