import React from 'react'
import {
    reduxForm,
    Field
} from 'redux-form'

class RSVPForm extends React.Component{

    renderError({error, touched}){
		if(error && touched){
			return(
                <div className="error mt-2" role="alert">
                    {error}
                </div>
			)
		}
	}

    renderInput = ({input, label, meta, type}) => {
        const className = `form-group ${meta.error && meta.touched ? 'in_error' : ''}`

        return (
            <div className={className}>
                <label>{label}</label>
                <input type={type} className="form-control" {...input} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        )
    }

    renderButton = () => {
        if(!this.props.clicked){
            return <button className="ldb_button mt-3">R S V P</button>
        } else {
            return <button className="ldb_button mt-3">submitting...</button>
        }
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){
        return(
            <form className="ldb_form ldb_form-rsvp" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field type="text" name="name" label="Name" component={this.renderInput} />
                <Field type="text" name="surname" label="Surname" component={this.renderInput} />
                <Field type="email" name="email" label="Email Address" component={this.renderInput} />
                <Field type="text" name="phone" label="Mobile Number" component={this.renderInput} />
                <Field type="text" name="fullname" label="Guest’s Full Name" component={this.renderInput} />
                <div className="form-group">
                    {this.renderButton()}
                </div>
            </form>
        )
    }
}

const validate = formValues => {
    const errors = {}

    if(!formValues.name){
        errors.name = "You must enter your name"
    }

    if(!formValues.surname){
        errors.surname = "You must enter your surname"
    }

    if(!formValues.email){
        errors.email = "You must enter your email address"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(formValues.email)){
        errors.email = "You must enter a valid email address"
    }

    if(!formValues.phone){
        errors.phone = "You must enter your phone number"
    } else if(formValues.phone.length < 1){
        errors.phone = "You must enter a valid mobile number"
    }

    if(!formValues.fullname){
        errors.fullname = "You must enter your full name"
    }

    return errors
}

export default reduxForm({
    form:'rsvp',
    validate
})(RSVPForm)