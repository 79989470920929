import React from 'react'

class HamburgerIcon extends React.Component{
    render(){
        return(
            <div className={`ldb_hamburger-menu-icon ${this.props.menuOpen ? 'open' : ''}`} onClick={this.props.onClick} title="Toggle sidemenu">
                <span />
                <span />
            </div>
        )
    }
}

export default HamburgerIcon