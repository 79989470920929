import React from 'react'
import Nav from '../components/Nav'
import {Helmet} from "react-helmet"
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import RSVPForm from '../components/RSVPForm'
import {connect} from 'react-redux'
import {
    submitRSVP,
    clearRSVP
} from '../actions'
import {Redirect} from 'react-router-dom'

class RSVP extends React.Component{

    state = {
        clicked: false
    }

    componentDidUpdate(){
        if(this.state.clicked){
            if(this.props.entry.name || this.props.entry.errors){
                this.setState({
                    clicked: false
                })
                setTimeout(() => {
                    this.props.clearRSVP()
                }, 3000)
                
            }
        }        
    }

    handelClicked = () => {
        this.setState({
            clicked: true
        })
    }

    renderErrors = () => {
        if(this.props.entry.errors){
            return Object.values(this.props.entry.errors).map((item, index) => {
                return (
                    <div class="alert alert-danger" role="alert">
                        {item[0]}
                    </div>
                )
            })
        }
    }

    onSubmit = formValues => {
        this.handelClicked()
        this.props.submitRSVP(formValues)
    }

    render(){
        
        if(this.props.entry.name){
            return <Redirect to="/year-end-party-2021-rsvp/thankyou" />
        }

        return(
            <React.Fragment>
                <Helmet>
                    
                    <title>Year End Part RSVP - LDB Construction UK Ltd</title>
                    <link rel="canonical" href={window.location} />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <Nav>
                    <section className="ldb_legal-section">
                        <Container>
                            <Row>
                                <Col sm={12} md={6}>
                                    <PageHeading 
                                        text="RSVP"
                                    />
                                    <p>Kindly let us know if you are able to attend our Year End Party.</p>
                                    <p>
                                        <strong>Date:</strong> Tuesday, 14 December 2021<br />
                                        <strong>Time:</strong> 18h30 – 23h30<br />
                                        <strong>Venue:</strong> No. 11 Pimlico Road
                                    </p>
                                    <p>We look forward to seeing you there!</p>
                                    <p>Kind regards,<br />
                                    <strong>LDB Construction</strong></p>
                                </Col>
                                <Col sm={12} md={6}>                                        
                                    <RSVPForm 
                                        onSubmit={this.onSubmit} 
                                        clicked={this.state.clicked}
                                    />
                                    {this.renderErrors()}
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>   
                <Footer />             
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        entry: state.entry
    }
}

export default connect(mapStateToProps, {
    submitRSVP,
    clearRSVP
})(RSVP)