import React from 'react'
import AuthLoginForm from './AuthLoginForm'
import {connect} from 'react-redux'
import {privateUserLogin} from '../actions'
import {
    Alert
} from 'react-bootstrap'

class AuthLogin extends React.Component{

    onSubmit = formValues => {
        this.props.privateUserLogin(formValues)
    }

    render(){
        return(
            <>
                {
                    this.props.auth.message && (
                        <Alert variant="danger">{this.props.auth.message}</Alert>
                    )
                }
                <AuthLoginForm onSubmit={this.onSubmit} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    privateUserLogin
})(AuthLogin)