import React from 'react'
import {connect} from 'react-redux'
import {privateUserLogout} from '../actions'

class AuthLogin extends React.Component{

    onSubmit = () => {
        this.props.privateUserLogout(this.props.auth.token)
    }

    render(){
        return(
            <button onClick={this.onSubmit} className="ldb_button mt-3 mb-5">
                Sign Out
            </button>
        )
    }
}

const mapStateToProps = state => {
    return{
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    privateUserLogout
})(AuthLogin)