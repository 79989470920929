import React from 'react'
import Nav from '../components/Nav'
import TopSection from '../components/TopSection'
import Footer from '../components/Footer'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import {Helmet} from "react-helmet";
import SectionHeading from '../components/SectionHeading'

//images
// import aboutImg3 from '../assets/img/about/ldb-video.jpg'
// import aboutImg4 from '../assets/img/about/01-about us@2x.jpg'
// import aboutImg5 from '../assets/img/about/02-about us@2x.jpg'
 import video from '../assets/videos/LBD About Us.mp4'

class About extends React.Component{

    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://www.ldbconstruction.com/#website",
                "url": "https://www.ldbconstruction.com/",
                "name": "LDB Construction",
                "description": "",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.ldbconstruction.com/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://www.ldbconstruction.com/about/#webpage",
                "url": "https://www.ldbconstruction.com/about/",
                "name": "About - LDB Construction UK Ltd",
                "isPartOf": {
                  "@id": "https://www.ldbconstruction.com/#website"
                },
                "datePublished": "2014-02-26T15:20:19+00:00",
                "dateModified": "2020-12-20T08:25:41+00:00",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://www.ldbconstruction.com/about/"
                    ]
                  }
                ]
              }
            ]
        }

        

        return(
          <React.Fragment>
          <Helmet>
              <title>About - LDB Construction UK Ltd</title>
              <link rel="canonical" href={window.location} />
              <meta name="robots" content="index, follow" />
              <meta
                  name="description"
                  content="LDB Construction is a luxury residential building, refurbishment and maintenance company specialising in design and build solutions for exclusive clients in prime areas of central London."
              />
              <script type="application/ld+json">
                  {JSON.stringify(ldJson)}
              </script>
          </Helmet>
          <Nav>
              <section className="ldb_about-section">
                  <Container>
                      <Row className="ldb_about-bodycopy">
                          <Col xs={12}>
                              <p>
                                  Founded in 2007, LDB Construction has become the leader in super-prime London construction.
                                  Our core approach is an uncompromising focus on quality and attention to detail, underpinned
                                  by responsible and safe working practices. We are an owner-run business, operating
                                  independently from external funding.
                              </p>
                              <p>
                                  LDB Construction’s professional and experienced management team works alongside handpicked
                                  craftsmen to offer a highly customisable service of impeccable workmanship. We are deeply
                                  committed to the business of building. Teamwork is foundational to our operations. We
                                  facilitate in-depth discussions with our clients and their design teams, taking the time to
                                  fully understand the goals and circumstances of each project we commit to. We also offer a
                                  comprehensive maintenance program for owners of properties we have built.
                              </p>
                              <p>Safety is our nonnegotiable.</p>
                              <p>
                                  In the pursuit of a sustainable future, we are constantly reviewing our business practices
                                  to ensure environmentally friendly solutions with an emphasis on practicality. As a result,
                                  LDB Construction forms lasting relationships with our clients and professional teams, who
                                  value our integrity and accountability as much as the exquisite homes we build.
                              </p>
                          </Col>
                      </Row>
                      <Row>
                          <Col xs={12}>
                              <div className="ldb_video">
                                  <video src={video} type="video/mp4" muted autoPlay loop playsInline controls />
                              </div>
                          </Col>
                      </Row>
      
                      {/* <Row>
                          <Col xs={12} className="ldb_about-images">
                              <img src={aboutImg4} alt="About LDB 1" />
                          </Col>
                          <Col xs={12} className="ldb_about-images">
                              <img src={aboutImg5} alt="About LDB 2" />
                          </Col>
                      </Row> */}
                  </Container>
              </section>
          </Nav>
          <Footer />
      </React.Fragment>
      
        )
    }
}

export default About